import { Atom } from ":mods";
import { createSignal, splitProps } from "solid-js";
import { LanguagesViewProps } from "./model";

export function Language(props:LanguagesViewProps){
  const [local, others] = splitProps(props, ["data"]);
  const radioContainerStyle = "flex gap-10px border border-2 border#p border-solid p-20px";
  const [moduleOpen, setModuleOpen] = createSignal(false);
  const handleAccordionClicks = () => {
    setModuleOpen(moduleOpen()?false:true);
  };
  const languagesLevel = ["beginner","intermediate","advanced"]
      return (
            <Atom.Foldable.Accordion
            arrowMode="end"
            iconClass={`w-24px h-24px  icon-local:down-arrow ${moduleOpen() && "!rotate-180"}`}
            onclick={handleAccordionClicks}
            title={
              <section class="flex flex-row justify-start items-center w-full font-700 select-none">
                <span>Languages (Foundation Sign Up)</span>
              </section>
            }
            class="border-2 border-solid border#p px-2 py-20px mb-20px"
          >
            <section class="flex flex-col gap-20px mt-40px">
            <div class="flex flex-col gap-10px">
            <p class="text-16px leading-22.4px">Do you speak Arabic?</p>
            <div class={radioContainerStyle}>
              <input
                type="radio"
                readOnly
                checked={local.data?.speak_english}
                class={"w-25px h-25px cursor-pointer"}
              />
              <p>Yes</p>
            </div>
            <div class={radioContainerStyle}>
              <input
                type="radio"
                readOnly
                checked={!local.data?.speak_english}
                class={"w-25px h-25px cursor-pointer"}
              />
              <p>No</p>
            </div>
          </div>
          <div class="flex flex-col gap-10px">
            <p class="text-16px leading-22.4px">Select your level of Arabic:</p>
            {languagesLevel.map((level)=>{
            return (
              <div class={radioContainerStyle}>
              <input
                type="radio"
                readOnly
                checked={local.data?.arabic_level?.toLowerCase() === level}
                class={"w-25px h-25px cursor-pointer"}
              />
              <p class="capitalize">{level}</p>
            </div>
            )
          })}
          </div>
          
            <div class="flex flex-col gap-10px">
            <p class="text-16px leading-22.4px">Do you speak English?</p>
            <div class={radioContainerStyle}>
              <input
                type="radio"
                readOnly
                checked={local.data?.speak_english}
                class={"w-25px h-25px cursor-pointer"}
              />
              <p>Yes</p>
            </div>
            <div class={radioContainerStyle}>
              <input
                type="radio"
                readOnly
                checked={!local.data?.speak_english}
                class={"w-25px h-25px cursor-pointer"}
              />
              <p>No</p>
            </div>
          </div>
          <div class="flex flex-col gap-10px">
            <p class="text-16px leading-22.4px">Select your level of English:</p>
            {languagesLevel.map((level)=>{
            return (
              <div class={radioContainerStyle}>
              <input
                type="radio"
                readOnly
                checked={local.data?.english_level?.toLowerCase() == level}
                class={"w-25px h-25px cursor-pointer"}
              />
              <p class="capitalize">{level}</p>
            </div>
            )
          })}
          </div>

            </section>
          </Atom.Foldable.Accordion>
      )
}